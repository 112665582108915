/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, MouseEvent, ReactNode } from "react";
import { OverlayTrigger, Tooltip, Spinner, ModalProps } from "react-bootstrap";
import { ModalProgressBar } from "../../../controls";
import { EditTabForm } from "./EditTabForm";
import stubFalse from "lodash/stubFalse";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";
import CustomModal from "../../../../../app/components/common/CustomModal";
import { Modal } from "react-bootstrap";
import SvgIcon from "../../../../../app/components/common/SvgIcon";
import { Access } from "../../../../../app/user-access/usePageAccessHook";
import { useAppDispatch, useAppSelector as useSelector } from "../../../../../app/common/_customHooks/redux";
import { useLocation, useNavigate } from "react-router-dom";
import WarningModal from "./WarningModal";

interface IProps {
  id: string | undefined;
  title: string;
  getItemAction?: any;
  saveItemAction?: any;
  clearItem: any;
  errorSelector: any;
  errorTitleSelector?:any;
  itemForEditSelector: any;
  isItemActionLoadingSelector: any;
  initialValues: {};
  validationSchema?: {};
  initialTouched?: {};
  isWizard?: boolean;
  access?: string;
  viewOnly?: boolean;
  showEdit?:boolean;
  setViewOnly?: any;
  selectedTabIndex?: number;
  onReset?: (item: any) => void;
  setValidationErrors?: any;
  getValidationErrors?: any;
  tabsWithValidations?: string[];
  onShowModal: boolean;
  onHideModal: (reloadItems:boolean) => void;
  formStateFilter?: (itemForEdit: any) => any;
  disableEditButton?: boolean;
  onSubmit?: (...args:any) => void;
  onKeyPress?: (e:any) => void;
  headerControls?:any;
  headerDecoratorElements?:any;
  formCustomClass?:string;
  saveLabel?:string;
  saveVariant?:string;
  ignoreWarning?:boolean;
  showTabbar?:boolean;
  getSelectedTabIndex?: (value:Number) => void;
  customSaveButton?:any;
  customControls?:any;
  customRightControls?:any;
  valuesUpdated?: (values:any) => void;
  modalProps?: ModalProps;
  children:ReactNode | ReactNode[];
  backTo?: string;
  checkDirty?:(val:boolean) => void;
}

export const EditItemModal: React.FC<IProps> = ({
  id = '',
  title,
  getItemAction,
  saveItemAction,
  clearItem,
  errorSelector,
  errorTitleSelector,
  itemForEditSelector,
  isItemActionLoadingSelector,
  initialValues,
  validationSchema,
  initialTouched,
  isWizard,
  access,
  viewOnly,
  showEdit = true,
  saveLabel = 'Save',
  saveVariant = 'primary',
  setViewOnly,
  selectedTabIndex = 0,
  children,
  onReset,
  setValidationErrors,
  getValidationErrors,
  tabsWithValidations,
  onShowModal,
  onHideModal,
  formStateFilter,
  disableEditButton,
  onSubmit,
  headerControls,
  headerDecoratorElements,
  formCustomClass='',
  onKeyPress,
  ignoreWarning=false,
  showTabbar=true,
  getSelectedTabIndex,
  customSaveButton,
  valuesUpdated,
  modalProps = {},
  backTo = '',
  customControls,
  customRightControls,
  checkDirty
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isCreate = location.pathname.includes("/new");

  const [dirty, setDirty] = useState<boolean>(false);
  const [ tab, setTab ] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(stubFalse);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [validationErrorTabIndex, setValidationErrorTabIndex] = useState<number | undefined>();
  const validationErrors: string[] = useSelector(getValidationErrors || (() => ""));
  const hasValidationErrors = Object.keys(validationErrors).length;

  const itemLoading: boolean = useSelector(isItemActionLoadingSelector);
  const itemForEdit: any = useSelector(itemForEditSelector);
  const error: any = useSelector(errorSelector);
  const errorTitle:any = useSelector(errorTitleSelector ? errorTitleSelector : (() => ''))

  const formValues = formStateFilter ? formStateFilter(itemForEdit) : itemForEdit;

  const item = id ? formValues || initialValues : initialValues;

  useEffect(() => {
    checkDirty && checkDirty(dirty)
  }, [dirty])

  useEffect(() => {
    getSelectedTabIndex && getSelectedTabIndex(tab)
  }, [tab]);

  useEffect(() => {
    if (!onShowModal && setValidationErrors) dispatch(setValidationErrors({}));
  }, [onShowModal]);

  useEffect(() => {
    if (id && !itemForEdit?.id && getItemAction) {
      dispatch(getItemAction(id));
    }

    return () => {
      if (id) dispatch(clearItem());
    };
  }, [getItemAction, id, dispatch]);

  useEffect(() => {
    if (error && error.length > 0) setShowError(true);
  }, [error]);

  useEffect(() => {
    const tabsWithErrors = Object.keys(validationErrors);

    if (saveClicked && tabsWithValidations) {
      tabsWithValidations.every((tab, index) => {
        if (tabsWithErrors.includes(tab)) {
          setValidationErrorTabIndex(index);
          return false;
        } else {
          return true;
        }
      });
    }

    if (!hasValidationErrors) {
      setSaveClicked(false);
    }
  }, [validationErrors, hasValidationErrors, saveClicked, tabsWithValidations]);

  const saveItem = async (item: any) => {
    if (saveItemAction) {
      const success = await dispatch(saveItemAction(item, id));
      if (success) backToItemsList();
    }
  };

  const btnRef = useRef<HTMLButtonElement>();
  const saveItemClick = () => {
    if (btnRef && btnRef.current) {
      setSaveClicked(true);
      btnRef.current.click();
    }
  };

  const btnResetRef = useRef<HTMLButtonElement>();
  const resetClick = () => {
    if (btnResetRef && btnResetRef.current) {
      if (onReset) onReset(item);
      btnResetRef.current.click();
    }
  };

  const backToItemsList = (reloadItems = true) => {
    resetClick();
    setShowWarning(false);
    setDirty(false);
    if (backTo) {
      navigate(backTo, {replace: true});
    } else {
      navigate(-1);
    }
    onHideModal(reloadItems);
    dispatch(setValidationErrors({}));
  };


  const titleData = title.split('****//****');
  const [staticData, dataPath, additionalDataPath ] = titleData;

  function getInfoFromKeys (item: {[key:string]: any}, keys: string): string { 
    const keysArray = keys ? keys.split('.') : []
    
    for (let i = 0; i < keysArray.length; i++) {
      item = item[keysArray[i]];
    }

    return item && typeof(item) === 'string' ? ` ${item}` : ''
  }

  const closeModal = (e?:MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (ignoreWarning) {
      backToItemsList(false)
    } else {
      dirty ? setShowWarning(true) : backToItemsList(false)
    }
  }
  
  return (
    <>
      {onShowModal && (
        <>
          <Modal
            {...modalProps}
            show={onShowModal}
            onHide={closeModal}
            size={modalProps.size || 'xl'}
            data-testid="crud-modal"
            dialogClassName="crud-modal"
            centered
          >
            <Modal.Header className="relative overflow-hidden">
              {itemLoading && <ModalProgressBar variant="primary" />}
              <Modal.Title data-testid="modal-title">
                {`${staticData && staticData} ${getInfoFromKeys(item, dataPath) || ''} ${getInfoFromKeys(item, additionalDataPath) || ''}`}
                {headerDecoratorElements}
              </Modal.Title>
              <section className="flex">
                {(access === Access.WRITE && !isCreate && showEdit) && (
                  <button
                    className={`btn btn-icon btn-sm ${viewOnly|| disableEditButton ? "btn-light" : "btn-primary"}`}
                    disabled={disableEditButton}
                    onClick={() => {
                      setViewOnly && setViewOnly(!viewOnly);
                    }}
                  >
                    <SvgIcon
                      iconLocation="/media/svg/icons/Design/Edit.svg"
                      size="3"
                      bootstrapColor={`${disableEditButton ? "secondary" : viewOnly ? "primary" : "white"}`}
                      title={`Edit ${title}`}
                    />
                  </button>
                )}
                {headerControls && headerControls[tab]}
                <div
                  data-testid="close-modal"
                  className="btn btn-icon btn-sm btn-hover-primary ml-4"
                  onClick={closeModal}
                >
                  <SvgIcon
                    iconLocation="/media/svg/icons/Navigation/Close-2.svg"
                    size="4"
                    bootstrapColor="grey"
                    title={`Close ${title}`}
                  />
                </div>
              </section>
            </Modal.Header>

            <Modal.Body>
              {error && <ErrorModal error={error} showError={showError} setShowError={setShowError} title={errorTitle}/>}
              <WarningModal
                showWarning={showWarning}
                setShowWarning={setShowWarning}
                backToItemsList={backToItemsList}
              />

              <div className={`${itemLoading ? "pointer-events-none" : ""} position-relative h-100`}>
                {itemLoading && <ModalLoadingSpinner />}
                <EditTabForm
                  onSubmit={onSubmit}
                  item={item}
                  btnResetRef={btnResetRef}
                  btnRef={btnRef}
                  saveItem={saveItem}
                  validationSchema={validationSchema}
                  initialTouched={initialTouched}
                  isEdit={itemForEdit && id}
                  isWizard={isWizard}
                  viewOnly={viewOnly}
                  selectedTabIndex={selectedTabIndex}
                  onTabChange={setTab}
                  setValidationErrors={setValidationErrors}
                  validationErrorTabIndex={validationErrorTabIndex}
                  saveClicked={saveClicked}
                  setSaveClicked={setSaveClicked}
                  formCustomClass={formCustomClass}
                  setDirty={setDirty}
                  onKeyPress={onKeyPress}
                  showTabbar={showTabbar}
                  valuesUpdated={valuesUpdated}
                >
                  {children}
                </EditTabForm>
              </div>
            </Modal.Body>

            <Modal.Footer className={`${access === Access.READ ? "p-0 border-0" : null}`}>
              {customRightControls}
              {onReset && <button className="btn btn-light" onClick={resetClick} disabled={itemLoading}>
                Reset
              </button>}
              {Object.keys(validationErrors).length ? (
                customSaveButton ||
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="save-form-tooltip">
                        <p className="required-field-marker mb-0">Mandatory fields missing</p>
                      </Tooltip>
                    }
                  >
                    <button
                      type="submit"
                      data-testid="warning-btn"
                      style={{ height: "3rem", minWidth: "7rem" }}
                      className="btn ml-2 btn-outline-warning d-flex justify-content-center align-items-center"
                      onClick={saveItemClick}
                      disabled={itemLoading}
                    >
                      <span style={{ lineHeight: "1" }}>{saveLabel}</span>{" "}
                      <i className="ml-2 flaticon-exclamation-1 pr-0" style={{ fontSize: "1.1em" }}></i>
                    </button>
                  </OverlayTrigger>
              ) : (
                access === Access.WRITE && (
                  customSaveButton || <button
                    type="submit"
                    className={`pr-4 pl-4 pt-3 pb-3 btn btn-${saveVariant} m-0 ml-2 d-flex justify-content-center align-items-center 
                  ${itemLoading ? "pointer-events-none" : ""} ${
                      !dirty || itemLoading || viewOnly ? "not-allowed" : null
                    }`}
                    onClick={saveItemClick}
                    disabled={!dirty || itemLoading || viewOnly}
                    data-testid="save-btn"
                  >
                    {itemLoading ? (
                      <>
                        <div
                          className="spinner spinner-white pr-4 pl-4 pt-3 pb-3"
                          style={{ margin: "0 0.5rem 0 -0.3rem", width: "1.5rem" }}
                        />
                        <>Processing</>
                      </>
                    ) : (
                      <>{saveLabel}</>
                    )}
                  </button>
                )
              )}
              {customControls}
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

interface IErrorProps {
  error: any;
  title?: string;
  showError: boolean;
  setShowError: React.Dispatch<React.SetStateAction<boolean>>;
}

const ErrorModal: React.FC<IErrorProps> = ({ error, title, showError, setShowError }) => {
  return (
    <CustomModal
      centered
      showModal={showError}
      customStyles={{ zIndex: "1060" }}
      title={title ? title : "Error"}
      body={
        <>
          {Array.isArray(error) ? (
            error.map((errorMessage, index) => (
              <p key={"message" + index}>
                <span>{errorMessage}</span>
              </p>
            ))
          ) : (
            <span>{error}</span>
          )}
        </>
      }
      buttons={[{ variant: "primary", onClick: () => setShowError(false), text: "Ok" }]}
    />
  );
};

const ModalLoadingSpinner: React.FC = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center position-absolute h-100 w-100"
      style={{ zIndex: 1, backgroundColor: "rgba(255,255,255,0.7)" }}
      data-testid="modal-loading-spinner"
    >
      <Spinner style={{ height: "3rem", width: "3rem" }} animation="border" variant="primary" />
    </div>
  );
};
