export const PATHS = {
    ROOT: 'route-planning',
    ROUTES_LIST: 'routes-list',
    ROUTE_PREVIEW: 'preview',
}

// List page
export const DATE = 'date';
export const ROLE_NAME = 'roleName';
export const ASSIGNED_TO_USER = 'assignedToUser';
export const DURATION_IN_SECONDS = 'durationInSeconds';
export const DISTANCE_IN_METERS = 'distanceInMeters';
export const STOPS = 'stopsCount';
export const TASKS = 'tasksCount';

// Task creation
export const TASK_SELECTION_FILTER_TAB = 'TASK_SELECTION_FILTER_TAB';
export const RANGE = 'range';
export const ID = 'id';
export const NAME = 'name';
export const CODE = 'code';
export const TERRIOTRY = 'territory';
export const ROLES = 'roles';
export const INCLUDE_ASSIGNED= 'includeAssigned';
export const TASK_TYPES= 'taskTypes';
export const SEGMENT= 'segments';
export const ISACTIVE = 'isActive';

export const TASK_SELECTION_TAB = 'TASK_SELECTION_TAB';

export const ROUTE_SETUP_TAB = 'ROUTE_SETUP_TAB';
export const START_LOCATION = 'startAddress';
export const END_LOCATION = 'endAddress';
export const VEHICLE_TYPE = 'vehicleType';
export const NUMBER_OF_VEHICLES = 'numberOfVehicles';
export const CAPACITY_OF_VEHICLE = 'capacityOfVehicle';

export const MAX_DISTANCE = 'maxDistance';
export const ENABLE_MAX_DISTANCE = 'enableMaxDistance';

export const MAX_SHIFT_TIME = 'maxShiftTime';
export const ENABLE_MAX_SHIFT_TIME = 'enableMaxShiftTime';

export const SHIFT_START_TIME = 'shiftStartTime';
export const SHIFT_END_TIME = 'shiftEndTime';

export const TOUR_END = 'tourEnd';
export const START_POINT = 'startPoint';
export const END_POINT = 'endPoint';
export const CUSTOM = 'custom';

export const ROUTE_ASSIGN_TAB = 'ROUTE_ASSIGN_TAB';
export const RELATIONS = 'relations';
export const VEHICLE_ID = 'vehicleId';
export const TYPE_ID = 'typeId';
export const ASSIGNED_TO_USER_ID = 'assignedToUserId';
export const ROUTES = 'routes';


// SAVED TOURS PLAN
export const PLANNED_ON_DATE = 'plannedOnDate';
export const ROUTE_CONFIGURATION = 'routeConfiguration';
export const MAX_DISTANCE_IN_METERS = 'maxDistanceInMeters';
export const MAX_SHIFT_TIME_IN_SECONDS = 'maxShiftTimeInSeconds';
export const SHIFT_START = 'shiftStart';
export const SHIFT_END = 'shiftEnd';
export const VEHICLE_TYPE_ID = 'vehicleTypeId';
export const ROUTE_ROLE_ID = 'routeRoleId';

export const ROUTE_COLORS = [
    "#5F5CF1",
    "#FF9505",
    "#3699FF",
    "#1BC5BD",
    "#F64E60",
    "#EB4B98",
    "#8950FC",
    "#7B4B94",
    "#084887",
    "#136F63",
    "#679436",
    "#9FD356",
]

export const TASK_TYPE_ICONS:any = {
    EM: 'ic-maintenance',
    CDE: 'ic-customer-development',
    MSA: 'ic-market-scan',
    SUR: 'ic-survey',
    VIS: 'ic-visit',
    NCUS: 'ic-new-customer',
    CCUS: 'ic-change-of-customer',
    ORD: 'ic-order',
    RPL: 'ic-replenishment',
    EPA: 'ic-placement-and-activation',
    ERD: 'ic-removal-and-deactivation',
    SCI: 'ic-3d-planner',
}