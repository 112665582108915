import { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import { InputGroup } from "react-bootstrap";
import debounce from "lodash/debounce";
import { deepCopy } from "../../../_helpers";
import { keyboardUtils } from "../../../../app/common/_utils";
import FieldClearButton from "../../../../app/components/common/Forms/FieldClearButton";

interface IProps {
  queryParams: any;
  setQueryParams: any;
  setPreventReload?: any;
  placeholder?: string;
  customClass?: string;
}

export const ItemsTableFilter: React.FC<IProps> = ({ queryParams, setQueryParams, setPreventReload, placeholder, customClass }) => {
  const formRef = useRef(null);

  const prevSearch = useRef({value: ''});

  const [search, setSearch] = useState(queryParams.filter || queryParams.Filter || "");

  const applyFilter = (values: any) => {
    const newQueryParams = deepCopy(queryParams);
    newQueryParams.Page = 1;
    newQueryParams.Filter = values.filter;
    setQueryParams(newQueryParams);
    if (setPreventReload) setPreventReload(false);
  };

  // Debounce Search
  const getSearchedItems = debounce((formRef) => {
      if (formRef) formRef.handleSubmit();
    }, 800)

  useEffect(() => {
    if (prevSearch.current.value !== search && search !== null) {
        getSearchedItems(formRef.current);
    }

    return () => {
      prevSearch.current.value = search;
    }
  }, [search]);

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={queryParams && (queryParams.filter || queryParams.Filter) ? { filter: queryParams.filter || queryParams.Filter } : { filter: "" }}
        onSubmit={(values:any) => {
          applyFilter(values);
        }}
      >
        {({ values, setFieldValue }:any) => (
          <div className={`form form-label-right mr-7 flex-1 ${customClass}`}>
            <div className="form-group row">
              <div className="col-lg-12">
                <InputGroup className="mb-3 relative">
                  <InputGroup.Text id="basic-addon1" className="rounded-none-top-right rounded-none-bottom-right">
                    <i className="fa fa-search"></i>
                  </InputGroup.Text>
                  <input
                    type="text"
                    className="form-control searchBox rounded-top-right rounded-bottom-right rounded-none-top-left rounded-none-bottom-left"
                    data-testid="searchBox"
                    name="filter"
                    aria-label="Filter"
                    placeholder={placeholder ? placeholder : "Type to search"}
                    value={values.filter}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearch(e.target.value);
                      setFieldValue("filter", e.target.value);
                    }}
                    onKeyPress={e => {
                      if (keyboardUtils.isEnterKey(e)) e.preventDefault();
                    }}
                  />
                  <FieldClearButton
                    value={search}
                    clearFunction={(val) => {
                      setSearch("");
                      setFieldValue("filter", val);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
};
