import React from "react";
import SVG from "react-inlinesvg";
import CustomModal from "../../../../../app/components/common/CustomModal";
import { toAbsoluteUrl } from "../../../../_helpers";

interface IWarningProps {
    showWarning: boolean;
    setShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
    backToItemsList: (state: boolean) => void;
}
  
const WarningModal: React.FC<IWarningProps> = ({ showWarning, setShowWarning, backToItemsList }) => {
    return (
        <CustomModal
            centered
            showModal={showWarning}
            customStyles={{ zIndex: "1060" }}
            title={"Are you sure?"}
            body={
                <h6 className="align-content-center mb-0 flex flex-row align-center">
                    <span className="svg-icon svg-icon-3x svg-icon-primary mr-5">
                        <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                    </span>
                    <span>All unsaved changes will be lost. Do you want to close anyway?</span>
                </h6>
            }
            buttons={[
                { variant: "secondary", onClick: () => setShowWarning(false), text: "Go back" },
                { variant: "primary", onClick: () => backToItemsList(false), text: "Close without saving" },
            ]}
        />
    );
};

export default WarningModal;